import React, { Component } from 'react';
import { Route } from 'react-router';
import './custom.css'
import { Medlemsoversigt } from './components/Medlemsoversigt'
import { Administration } from './components/Administration';
import { NavMenu } from './components/NavMenu';
import { Button, Form, Container, Row, Col } from 'react-bootstrap';
import { Medlemsoprettelse } from './components/Medlemsoprettelse';

export default class Admin extends Component {
    static displayName = Admin.name;

    constructor(props) {
        super(props);
        this.state = {
            token: "",
            authorized: false,
            validationFailed: false
        };
        this.handleAuthorizationSubmit = this.handleAuthorizationSubmit.bind(this);
        this.getAuthorizationToken = this.getAuthorizationToken.bind(this);
    }


    handleAuthorizationSubmit(event) {
        event.preventDefault();
        this.getAuthorizationToken(event.target[0].value);
    }

    render() {
        let contents =
            this.state.validationFailed
                ? <div className="adminpasswordfield">
                    Kodeordet er ikke gyldigt.
                    <br/>
                    Kontakt en JUS-Administrator hvis du mener at du burde have adgang til JUS-Medlem's administrationsside.
                </div>
                :   this.state.authorized
            ? <div>
                <NavMenu />
                <Route path='/admin/medlemsoversigt' component={() => (<Medlemsoversigt authToken={this.state.token}/>)} />
                <Route path='/admin/administration' component={() => (<Administration authToken={this.state.token} />)} />
                <Route path='/admin/medlemsoprettelse' component={() => (<Medlemsoprettelse authToken={this.state.token} />)} />
            </div>
                    : <Row>
                        <Col>
                            <Form onSubmit={(e) => this.handleAuthorizationSubmit(e)} className="adminpasswordfield">
                                <Form.Group controlId="loginPassword">
                                    <Form.Control type="password" placeholder="Kodeord" />
                                </Form.Group>
                            </Form>
                        </Col>
                    </Row>
            

        return (
            <div>
                {contents}
            </div>
        );
    }

    async getAuthorizationToken(input) {
        const response = await fetch('administration/getauthorizationtoken', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ input: input })
        });
        const data = await response.json();
        if (data.validated) {
            this.setState({
                token: data.token,
                authorized: true
            })
        } else {
            this.setState({ validationFailed: true })
        }
    }
}
