import React, { Component } from 'react';
import { Button, Form, Container, Row, Col } from 'react-bootstrap';

export class Medlemsoprettelse extends Component {
    static displayName = Medlemsoprettelse.name;

    constructor(props) {
        super(props);
        this.state = {
            authToken: this.props.authToken,
            form: {
                fornavn: "",
                efternavn: "",
                studienummer: "",
                semester: -1,
                telefonnummer: "",
                mailadresse: "",
                betalt: false,
                modtagetkort: false
            },
            formValidated: false,
            loading: false,
            submitted: false,
            success: true
        }
        this.handleFormSubmit = this.handleFormSubmit.bind(this);
        this.handleFormChangeFornavn = this.handleFormChangeFornavn.bind(this);
        this.handleFormChangeEfternavn = this.handleFormChangeEfternavn.bind(this);
        this.handleFormChangeSemester = this.handleFormChangeSemester.bind(this);
        this.handleFormChangeStudienummer = this.handleFormChangeStudienummer.bind(this);
        this.handleFormChangeTelefonnummer = this.handleFormChangeTelefonnummer.bind(this);
        this.handleFormChangeMailadresse = this.handleFormChangeMailadresse.bind(this);
        this.handleFormChangeBetalt = this.handleFormChangeBetalt.bind(this);
        this.renderForm = this.renderForm.bind(this);
    }

    handleFormSubmit(event) {
        event.preventDefault(); // Prevents page load.
        const form = event.currentTarget;
        if (form.checkValidity() === true) {
            this.setState({ loading: true, formValidated: true });
            this.opretMedlem();
        } else {
            event.stopPropagation();
        }
    }

    handleFormChangeFornavn(event) {
        let newForm = this.state.form;
        newForm.fornavn = event.target.value;
        this.setState({ form: newForm })
    }

    handleFormChangeEfternavn(event) {
        let newForm = this.state.form;
        newForm.efternavn = event.target.value;
        this.setState({ form: newForm })
    }

    handleFormChangeStudienummer(event) {
        let newForm = this.state.form;
        newForm.studienummer = event.target.value;
        this.setState({ form: newForm })
    }

    handleFormChangeSemester(event) {
        let newForm = this.state.form;
        newForm.semester = event.target.value;
        this.setState({ form: newForm })
    }

    handleFormChangeTelefonnummer(event) {
        let newForm = this.state.form;
        newForm.telefonnummer = event.target.value;
        this.setState({ form: newForm })
    }

    handleFormChangeMailadresse(event) {
        let newForm = this.state.form;
        newForm.mailadresse = event.target.value;
        this.setState({ form: newForm })
    }

    handleFormChangeBetalt(event) {
        let newForm = this.state.form;
        newForm.betalt = event.target.checked;
        this.setState({ form: newForm })
    }

    handleFormChangeModtagetKort(event) {
        let newForm = this.state.form;
        newForm.modtagetkort = event.target.checked;
        this.setState({ form: newForm })
    }

    renderForm() {
        return (
            <Row>
                <Col>
                    <Form validated={ this.state.formValidated } onSubmit={this.handleFormSubmit} >
                        <Form.Group controlId="formFornavn">
                            <Form.Label>Fornavn</Form.Label>
                            <Form.Control type="text"
                                placeholder="Fornavn"
                                onChange={this.handleFormChangeFornavn}
                                required
                            />
                            <Form.Control.Feedback>Ser godt ud!</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group controlId="formEfternavn">
                            <Form.Label>Efternavn</Form.Label>
                            <Form.Control type="text"
                                placeholder="Efternavn"
                                onChange={this.handleFormChangeEfternavn}
                                required
                            />
                        </Form.Group>
                        <Form.Group controlId="formStudienummer">
                            <Form.Label>Studienummer</Form.Label>
                            <Form.Control type="number"
                                placeholder="Studienummer"
                                onChange={this.handleFormChangeStudienummer}
                                required
                            />
                        </Form.Group>
                        <Form.Group controlId="formSemester">
                            <Form.Label>Semester</Form.Label>
                            <Form.Control type="number"
                                placeholder="Semester"
                                onChange={this.handleFormChangeSemester}
                                required
                            />
                        </Form.Group>
                        <Form.Group controlId="formTelefonnummer">
                            <Form.Label>Telefonnummer</Form.Label>
                            <Form.Control type="number"
                                placeholder="Telefonnummer"
                                onChange={this.handleFormChangeTelefonnummer}
                                required
                            />
                        </Form.Group>
                        <Form.Group controlId="formEmail">
                            <Form.Label>Mailadresse</Form.Label>
                            <Form.Control type="text"
                                placeholder="Mailadresse"
                                onChange={this.handleFormChangeMailadresse}
                                required
                            />
                        </Form.Group>
                        <Form.Group controlId="formBetalt">
                            <Form.Check
                                type="checkbox"
                                id="formBetaltCheckbox"
                                label="Har Betalt"
                                checked={this.state.form.betalt}
                                onChange={(e) => this.handleFormChangeBetalt(e)}
                            />
                        </Form.Group>
                        <Form.Group controlId="formModtagetKort">
                            <Form.Check
                                type="checkbox"
                                id="formModtagetKortCheckbox"
                                label="Har Modtaget Kort"
                                checked={this.state.form.modtagetkort}
                                onChange={(e) => this.handleFormChangeModtagetKort(e)}
                            />
                        </Form.Group>
                        <Button variant="primary" type="submit">
                            Registrér
                        </Button>
                    </Form>
                </Col>
            </Row>
        )
    }

    render() {
        let contents = <div />
        if (this.state.submitted == false) {
            contents = this.state.loading
                ? <p><em>Opretter medlem...</em></p>
                : this.renderForm();
        } else {
            contents = (<div>
                <p>Du har oprettet medlemmet {this.state.form.fornavn + " " + this.state.form.efternavn}</p>
                <p>Du kan registrere eventuel betaling og modtagelse af kort i medlemsoversigten.</p>
            </div>);
        }

        return (
            <div>
                <Container>
                    <Row>
                        <h1>Medlemsoprettelse</h1>
                    </Row>
                    {contents}
                </Container>
            </div>
        );
    }

    async opretMedlem() {
        const form = this.state.form;
        const response = await fetch('medlemsoprettelse/opretmedlem', {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + this.state.authToken,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(form)
        });
        this.setState({ submitted: true, success: response.ok, loading: false });
    }
}