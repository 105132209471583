import React, { Component } from 'react';
import { Button, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import juslogo from '../media/jusikon.png';

export class Home extends Component {
  static displayName = Home.name;

  render () {
    return (
          <div className="userinterface-card">
            <Row>
                <Col>
                <div>
            <h1>JUS-Medlem</h1>
                    <p>Velkommen til JUS-Medlem</p>
                    </div>
                </Col>
                <Col>
                    <img src={juslogo} className="jus-icon" alt="JUS Logo" width="200" height="200"/>
                </Col>
            </Row>
            <Row>
                <Col>
                    <div>
                        <br />
                        <br/>
                    <p>Tryk her for at oprette dig som medlem </p>
                    <Link to="/medlemsregistrering">
                        <Button variant="outline-primary" className="text-dark">Oprettelse</Button>
                    </Link>
                    </div>
                </Col>
            </Row>
            <Row>
                <hr />
            </Row>
            <Row>
                <Col>
                    <div>
                        <hr/>
                    <p>Tryk her for at komme til den administrative sektion</p>
                    <Link to="/admin/medlemsoversigt">
                        <Button variant="outline-primary" className="text-dark">Administrator</Button>
                    </Link>
                    </div>
                </Col>
            </Row>
      </div>
    );
  }
}
