import React, { Component } from 'react';
import { Button, Form, Row, Col } from 'react-bootstrap';
import juslogo from '../media/jusikon.png';

export class Medlemsregistrering extends Component {
    static displayName = Medlemsregistrering.name;

    constructor(props) {
        super(props);
        this.state = {
            form: {
                fornavn: "",
                efternavn: "",
                studienummer: "",
                semester: "",
                telefonnummer: "",
                mailadresse: "",
            },
            loading: false,
            submitted: false,
            success: true,
            formHasErrors: false,
            responseText: "",
            paymentUrl: ""
        }
        this.handleFormSubmit = this.handleFormSubmit.bind(this);
        this.handleFormChangeFornavn = this.handleFormChangeFornavn.bind(this);
        this.handleFormChangeEfternavn = this.handleFormChangeEfternavn.bind(this);
        this.handleFormChangeSemester = this.handleFormChangeSemester.bind(this);
        this.handleFormChangeStudienummer = this.handleFormChangeStudienummer.bind(this);
        this.handleFormChangeTelefonnummer = this.handleFormChangeTelefonnummer.bind(this);
        this.handleFormChangeMailadresse = this.handleFormChangeMailadresse.bind(this);
        this.renderForm = this.renderForm.bind(this);
        this.formValidation = this.formValidation.bind(this);
    }

    formValidation(form) {
        if (form.fornavn === "")
            return false;
        if (form.efternavn === "")
            return false;
        if (form.semester > 11)
            return false;
        if (form.telefonnummer === "")
            return false;

        return true;
    }

    handleFormSubmit(event) {
        event.preventDefault(); // Prevents page load.
        const form = event.currentTarget;
        if (this.formValidation(form)) {
            this.setState({ loading: true });
            this.registrerMedlem();
        } else {
            event.stopPropagation();
            this.setState({
                formHasErrors: true
            })
        }
    }

    handleFormChangeFornavn(event) {
        let newForm = this.state.form;
        newForm.fornavn = event.target.value;
        this.setState({ form: newForm })
    }

    handleFormChangeEfternavn(event) {
        let newForm = this.state.form;
        newForm.efternavn = event.target.value;
        this.setState({ form: newForm })
    }

    handleFormChangeStudienummer(event) {
        let newValue = event.target.value;
        let newForm = this.state.form;
        newForm.studienummer = newValue;
        this.setState({ form: newForm })
    }

    handleFormChangeSemester(event) {
        let newValue = event.target.value;
        let newForm = this.state.form;
        newForm.semester = newValue;
        this.setState({ form: newForm })
    }

    handleFormChangeTelefonnummer(event) {
        let newForm = this.state.form;
        newForm.telefonnummer = event.target.value;
        this.setState({ form: newForm })
    }

    handleFormChangeMailadresse(event) {
        let newForm = this.state.form;
        newForm.mailadresse = event.target.value;
        this.setState({ form: newForm })
    }

    renderForm() {

        let responseArea =
            <div>
                <b>Der var en fejl i din registrering.</b>
                <br />
                <p>{this.state.responseText}</p>
            </div>

        return (
            <div>
                <Row>
                    {(!this.state.success && this.state.responseText !== "") ?
                    responseArea : <div />}
            </Row>
            <Row>
                <Col>
                    <Form onSubmit={this.handleFormSubmit} >
                            <Form.Group controlId="formFornavn" className="registrationfield">
                            <Form.Label>Fornavn</Form.Label>
                            <Form.Control type="text"
                                placeholder="Fornavn"
                                    onChange={this.handleFormChangeFornavn}
                                    value={this.state.form.fornavn}
                                required
                                />
                                <Form.Text className="text-muted" id="formText">
                                    Inkludér mellemnavne her
                                </Form.Text>
                        </Form.Group>
                            <Form.Group controlId="formEfternavn" className="registrationfield">
                            <Form.Label>Efternavn</Form.Label>
                            <Form.Control type="text"
                                placeholder="Efternavn"
                                    onChange={this.handleFormChangeEfternavn}
                                    value={this.state.form.efternavn}
                                required
                            />
                        </Form.Group>
                            <Form.Group controlId="formStudienummer" className="registrationfield">
                            <Form.Label>Studienummer</Form.Label>
                            <Form.Control type="number"
                                placeholder="Studienummer"
                                    onChange={this.handleFormChangeStudienummer}
                                    value={this.state.form.studienummer}
                                required
                            />
                        </Form.Group>
                            <Form.Group controlId="formSemester" className="registrationfield">
                            <Form.Label>Semester</Form.Label>
                            <Form.Control type="number"
                                placeholder="Semester"
                                    onChange={this.handleFormChangeSemester}
                                    value={this.state.form.semester}
                                required
                                />
                            <Form.Text className="text-muted" id="formText">
                                Skal være mellem 1 til og med 11
                            </Form.Text>
                        </Form.Group>
                            <Form.Group controlId="formTelefonnummer" className="registrationfield">
                            <Form.Label>Telefonnummer</Form.Label>
                            <Form.Control type="number"
                                placeholder="Telefonnummer"
                                    onChange={this.handleFormChangeTelefonnummer}
                                    value={this.state.form.telefonnummer}
                                required

                            />
                        </Form.Group>
                            <Form.Group controlId="formEmail" className="registrationfield">
                            <Form.Label>Mailadresse</Form.Label>
                            <Form.Control type="text"
                                placeholder="Mailadresse"
                                    onChange={this.handleFormChangeMailadresse}
                                    value={this.state.form.mailadresse}
                                required
                            />
                            <Form.Text className="text-muted">
                                Benyttes til at sende dig mail om betaling af dit kontingent og senere bekræftelse.
                            </Form.Text>
                        </Form.Group>
                            <Button variant="primary" type="submit" className="registrationfield">
                            Registrér
                        </Button>
                    </Form>
                </Col>
                </Row>
            </div>
        )
    }

    render() {
        let contents = <div />
        if (this.state.submitted == false) {
            contents = this.state.loading
                ? <p><em>Opretter medlem...</em></p>
                : this.renderForm();
        } else {
            if (this.state.success) {
                contents = (<div>
                    <p>Du er nu oprettet som medlem i Juridisk Selskab.</p>
                    <p>Du kan benytte dig af linket <a href={this.state.paymentUrl}>her</a> for at betale for dit kontingent.</p>
                    <p>Du modtager derudover også en mail på <b>{this.state.form.mailadresse}</b> til betalingslinket. Tjek din spam-folder hvis du ikke kan finde mailen.</p>
                    <p>Foretrækker du en anden betalingsform, kan du tage fat i en rådsadministrator.</p>
                </div>);
            } else {
                contents = this.renderForm();
            }
        }

        return (
            <div className="userinterface-card">
                <Col>
                    <Row>
                        <Col>
                            <h1>Opret dig hos JUS</h1>
                        </Col>
                        <Col>
                            <img src={juslogo} className="jus-icon" alt="JUS Logo" width="100" height="100" />
                        </Col>
                    </Row>
                    {contents}
                </Col>
            </div>
        );
    }

    async registrerMedlem() {
        const form = this.state.form;
        const response = await fetch('medlemsregistrering/opretmedlem', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(form)
        });
        const data = await response.json();
        this.setState({ submitted: true, responseText: data.responseText, submitted: data.success, success: data.success, loading: false, paymentUrl: data.paymentUrl });
    }
}