import React, { Component } from 'react';
import { Button, Form, Container, Row, Col } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

export class Medlemsbetaling extends Component {
    static displayName = Medlemsbetaling.name;

    constructor(props) {
        super(props);
        this.state = {
            fornavn: this.props.match.params.fornavn,
        }
    }


    render() {

        let fornavn = this.state.fornavn
        fornavn = fornavn.Replace('AE', 'Æ').Replace('OE', 'Ø').Replace('AA', 'Å');
        fornavn = fornavn.Replace('ae', 'æ').Replace('oe', 'ø').Replace('aa', 'å');

        return (
            <div className="userinterface-card">
                <Row>
                    <Col>
                    <p>Kære {fornavn}</p>
                        <p>Din betaling er bekræftet. Du modtager snart en mail med bekræftelsen. Du bedes tage fat i en rådsadministrator for at modtage dit medlemskort</p>
                    </Col>
                </Row>
            </div>
        );
    }
}