import React, { Component } from 'react';
import { Button, Form, Container, Row, Col } from 'react-bootstrap';

export class Administration extends Component {
    static displayName = Administration.name;

    constructor(props) {
        super(props);
        this.state = {
            authToken: this.props.authToken,
            semesterskiftLastDate: "Indlæser",
            semesterskiftLoad: false,
            semesterskiftComplete: false,
            emailBodies: [],
            emailBodiesChanged: [],
            emailBodiesloading: true,
            pricesChanged: false,
            pricesLoading: true,
            priceFirstYear: 0,
            priceSecondYear: 0,
            priceThirdYear: 0
        };
        this.handleSemesterskift = this.handleSemesterskift.bind(this);
        this.editableField = this.emailBodyField.bind(this);
    }

    handleEditableFieldEmailBody(event, id) {
        event.preventDefault();

        this.handleEmailbodyUpdate({
            id: id,
            newValue: event.target[0].value
        })
    }

    updateEmailBodiesChanged(id, add) {
        let newEmailBodiesChanged = this.state.emailBodiesChanged;
        if (add) {
            if (!this.state.emailBodiesChanged.includes(id)) {
                newEmailBodiesChanged.push(id);
            }
        } else {
            newEmailBodiesChanged = newEmailBodiesChanged.filter(id => id !== id);
        }
        this.setState({ emailBodiesChanged: newEmailBodiesChanged })
    }

    emailBodyField(id, value) {
        return (
            <Form onSubmit={(e) => this.handleEditableFieldEmailBody(e, id)}>
                <textarea className='form-control h-auto adminfield'
                    id={"emailbody-" + id}
                    rows='12'
                    defaultValue={value}
                    onChange={(e) => { this.updateEmailBodiesChanged(id, true) }}
                />
                <div id={"emailbody-" + id}></div>
                <Button
                    variant={this.state.emailBodiesChanged.includes(id) ? "primary" : "outline-primary"}
                    disabled={!this.state.emailBodiesChanged.includes(id)}
                    type="submit">
                    Opdater Emailtekst
                </Button>
            </Form>
            )
    }

    handleSemesterskift(event) {
        event.preventDefault();
        this.setState({ semesterskiftLoad: true });
        this.semesterskiftAction();
    }

    componentDidMount() {
        this.semesterskiftLastDate();
        this.getEmailBodies();
        this.getPrices();
    }

    handlePriceChange(event) {
        event.preventDefault();
        this.setState({ pricesChanged: false })
        this.updatePrices({
            firstYear: event.target[0].value,
            secondYear: event.target[1].value,
            thirdYear: event.target[2].value,
        })
    }

    render() {

        let semesterskift = this.state.semesterskiftLoad ?
            (
                this.state.semesterskiftComplete ?
                    (
                        <Form>
                            <Button variant="secondary" type="submit" disabled aria-disabled="true" >
                                Semesterskift
                            </Button>
                            <Form.Text className="text-muted">
                                Semesterskift udført!
                                Sidste semesterskift var <b> {this.state.semesterskiftLastDate} </b>
                            </Form.Text>
                            <hr />
                        </Form>
                        )
                        :
                        (
                        <Form onSubmit = {this.handleSemesterskift}>
                            <Button variant="primary" type="submit" disabled aria-disabled="true" >
                                Semesterskift
                            </Button>
                            <Form.Text className="text-muted">
                                Semesterskift er igang.
                            </Form.Text>
                            <hr />
                        </Form >
                        )

            )
            :
            (
                <Form onSubmit={this.handleSemesterskift}>
                    <Button variant="primary" type="submit" aria-disabled="false" >
                        Semesterskift
                    </Button>
                    <Form.Text className="text-muted">
                        Øger semesteret på alle medlemmer i systemet med 1 og sletter medlemmer på et semester-antal højere end 11.
                        Sidste semesterskift var <b> {this.state.semesterskiftLastDate} </b>
                    </Form.Text>
                    <hr />
                </Form>
            )

        let emailBodiesContent = this.state.emailBodiesloading ?
            <Row>
                <i>Indlæser Emailindhold</i>
            </Row>
            :
            this.state.emailBodies.map(emailBody =>
                <Row key={emailBody.id} >
                    <Col>
                    <Row>
                        Emailindhold for emailkode '{emailBody.code}'
                        </Row>
                    <Row>
                            Subjekttekst: <b>{emailBody.subject}</b>
                    </Row>
                    <Row style={{ display: "block" }}>
                        {this.emailBodyField(emailBody.id, emailBody.body)}
                    </Row>
                    <Row>
                        <i>Sidst Ændret: {emailBody.sidstAendret}</i>
                        </Row>
                        <hr />
                        </Col>
                </Row>
            )

        let priceSettings = (
            (this.state.pricesLoading ?
                <Row>
                    <Col>
                        <i>Indlæser priser...</i>
                        <hr/>
                    </Col>
                </Row>
            :
            <Row>
                <Col>
                <Row>
                    <h3>Priser for tilmelding</h3>
                </Row>
                <Row>
                <Form onSubmit={(e) => this.handlePriceChange(e)}>
                                <Form.Text className="text-muted">
                                    Pris for førsteårsmedlemmer
                                </Form.Text>
                                <Form.Control
                                    className="adminfield"
                        type="text"
                        id={"pricefieldOneYear"}
                        defaultValue={this.state.priceFirstYear}
                        label="Førsteår"
                        onChange={(e) => { this.setState({ pricesChanged: true }) }}
                                />
                                <Form.Text className="text-muted">
                                    Pris for andetårsmedlemmer
                                </Form.Text>
                                <Form.Control
                                    className="adminfield"
                        type="text"
                        id={"pricefieldTwoYear"}
                        defaultValue={this.state.priceSecondYear}
                        label="Andetår"
                        onChange={(e) => { this.setState({ pricesChanged: true }) }}
                                />
                                <Form.Text className="text-muted">
                                    Pris for tredjeårsmedlemmer
                                </Form.Text>
                                <Form.Control
                                    className="adminfield"
                        type="text"
                        id={"pricefieldThreeYear"}
                        defaultValue={this.state.priceThirdYear}
                        label="Tredjeår og frem"
                        onChange={(e) => { this.setState({ pricesChanged: true }) }}
                                />
                    <Button
                        variant={this.state.pricesChanged ? "primary" : "outline-primary"}
                        disabled={!this.state.pricesChanged}
                        type="submit">
                        Opdatér Priser
                    </Button>
                    </Form>
                    </Row>
                    <hr />
                    </Col>
            </Row>
            ))

        let emailHelper = (
            <Row>
                <Col>
                    <p>Ønsker du vejledning til brug af HTML-tags, besøg <a href="https://www.w3schools.com/html/html_intro.asp">W3Schools</a> her.</p>
                    <p>Benyt dig af følgende Felter i email-teksten for at få siden til automatisk at indsætte navne og links. De bliver udskiftet i selve mailen med de rigtige værdier når mailen afsendes.</p>
                    <p><b>[fornavn]</b> bliver byttet ud med medlemmets fornavn.</p>
                    <p><b>[betalingslink]</b> bliver byttet ud med et link til at betale for medlemmets kontingent. Betalingens pris afhænger af medlemmets nuværende semester.</p>
                    <p><b>[pris]</b> bliver byttet ud med betalingens pris. Kan også benyttes inden betaling.</p>
                    <p><b>[dato]</b> bliver byttet ud med dagens dato.</p>
                    <hr />
                </Col>
            </Row>
            )


        return (
            <div>
                <Container>
                    <Col>
                    <Row>
                        <h1>Administration</h1>
                    </Row>
                    <Row>
                        {semesterskift}
                    </Row>
                    {priceSettings}
                    {emailHelper}
                        {emailBodiesContent}
                    </Col>
                </Container>
            </div>
        );
    }

    async updatePrices(newPriceOverview) {
        const response = await fetch('administration/updateprices', {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + this.state.authToken,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(newPriceOverview)
        });
        if (response.ok)
            this.setState({ pricesChanged: false })
    }

    async getPrices() {
        const response = await fetch('administration/getprices', {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + this.state.authToken,
                'Content-Type': 'application/json'
            }
        });
        const data = await response.json();
        this.setState({ priceFirstYear: data.firstYear, priceSecondYear: data.secondYear, priceThirdYear: data.thirdYear, pricesLoading: false })
    }

    async getEmailBodies() {
        const response = await fetch('administration/getallemailbodies', {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + this.state.authToken,
                'Content-Type': 'application/json'
            }
        });
        const data = await response.json();
        this.setState({ emailBodies: data, emailBodiesloading: false })
    }


    async handleEmailbodyUpdate(updateEmailBody) {
        const response = await fetch('administration/updateemailbody', {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + this.state.authToken,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(updateEmailBody)
        });

        if (response.ok) {
            let newEmailBodies = this.state.emailBodies.slice();
            // Id's come back as strings in data due to JSON conversion.
            newEmailBodies = newEmailBodies.map(emailBody => { if (emailBody.id == updateEmailBody.id) { return { ...emailBody, body: updateEmailBody.newValue, sidstAendret: new Date().toLocaleString('en-GB', { timeZone: 'Europe/Copenhagen' }) } } else { return emailBody } });
            this.updateEmailBodiesChanged(updateEmailBody.id, false);
            this.setState({ emailBodies: newEmailBodies });
        }
    }

    async semesterskiftLastDate() {
        const response = await fetch('administration/semesterskiftlastdate', {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + this.state.authToken,
                'Content-Type': 'application/json'
            }
        });
        const data = await response.json();
        this.setState({ semesterskiftLastDate: data.lastDate });
    }

    async semesterskiftAction() {
        const response = await fetch('administration/semesterskiftaction', {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + this.state.authToken,
                'Content-Type': 'application/json'
            }
        });
        this.setState({ semesterskiftComplete: response.ok });
        this.semesterskiftLastDate();
    }


}
