import React, { Component } from 'react';
import { Container } from 'reactstrap';
import { Route } from 'react-router';
import { Layout } from './components/Layout';
import { Home } from './components/Home';
import './custom.css'
import { Medlemsregistrering } from './components/Medlemsregistrering';
import { Medlemsbetaling } from './components/Medlemsbetaling';
import Admin from './Admin';

export default class App extends Component {
    static displayName = "JUS-Medlem";



  render () {
      return (
      <Container>
        <Layout>
            <Route exact path='/' component={Home} />
            <Route path='/admin' component={Admin} />
            <Route path='/medlemsregistrering' component={Medlemsregistrering} />
            <Route path='/medlemsbetaling/:fornavn' component={Medlemsbetaling} />
        </Layout>
    </Container>
    );
  }
}
