import React, { Component } from 'react';
import { Button, Form, Container, Row, Col } from 'react-bootstrap';

export class Medlemsoversigt extends Component {
    static displayName = Medlemsoversigt.name;

    constructor(props) {
        super(props);
        this.state = {
            authToken: this.props.authToken,
            medlemmer: [],
            loading: true,
            filter: {
                navn: "",
                studienummer: "",
                semester: -1,
                telefonnummer: "",
                mailadresse: "",
                betalt: -1,
                modtagetKort: -1,
            },
            activeCell: {
                medlemId: 2504,
                field: ""
            },
            enableDeletion: false
        };
        this.loadMembers = this.loadMembers.bind(this);
        this.handleFilterChangeName = this.handleFilterChangeName.bind(this);
        this.handleFilterChangeStudienummer = this.handleFilterChangeStudienummer.bind(this);
        this.handleFilterChangeSemester = this.handleFilterChangeSemester.bind(this);
        this.handleFilterChangeTelefonnummer = this.handleFilterChangeTelefonnummer.bind(this);
        this.handleFilterChangeMailadresse = this.handleFilterChangeMailadresse.bind(this);
        this.handleFilterChangeBetalt = this.handleFilterChangeBetalt.bind(this);
        this.handleFilterChangeModtagetKort = this.handleFilterChangeModtagetKort.bind(this);
        this.handleFilterSubmit = this.handleFilterSubmit.bind(this);
        this.populateMedlemmer = this.populateMedlemmer.bind(this);
        this.renderMedlemmerTable = this.renderMedlemmerTable.bind(this);
        this.editableField = this.editableField.bind(this);
        this.handleEditableFieldCheckbox = this.handleEditableFieldCheckbox.bind(this);
        this.handleEditableFieldText = this.handleEditableFieldText.bind(this);
        this.handleEditableFieldChange = this.handleEditableFieldChange.bind(this);
        this.updateMedlem = this.updateMedlem.bind(this);
        this.deleteMedlem = this.deleteMedlem.bind(this);
        this.handleDeleteMedlem = this.handleDeleteMedlem.bind(this);
    }

    loadMembers() {
        this.populateMedlemmer();
    }

    componentDidMount() {
        this.populateMedlemmer();
    }

    handleFilterSubmit(event) {
        event.preventDefault(); // Prevents page load.
        this.setState({ loading: true })
        this.loadMembers();
    }

    editableField(id, value, field) {

        let contents = (
            id === this.state.activeCell.medlemId
            && this.state.activeCell.field === field)
            ? <td>
                <Form onSubmit={(e) => this.handleEditableFieldText(e, field)}>
                <Form.Control
                    type="text"
                    id={field + "-" + id}
                    defaultValue={value}

                />
                </Form>
            </td>
            : <td
                onClick={() => {
                    let newActiveCell = this.state.activeCell;
                    newActiveCell.medlemId = id;
                    newActiveCell.field = field;
                    this.setState({ activeCell: newActiveCell })
                }}
            >
                {value}
            </td>
                
        return contents;
    }

    handleEditableFieldCheckbox(event, field, medlemId) {
        event.preventDefault();

        this.handleEditableFieldChange(event.target.checked ? "1" : "0", field, medlemId);
    }

    handleEditableFieldText(event, field) {
        event.preventDefault();

        const id = this.state.activeCell.medlemId;

        this.handleEditableFieldChange(event.target[0].value, field, id)
    }

    handleEditableFieldChange(value, field, id) {
        this.setState({
            activeCell: {
                medlemId: -1,
                field: ""
            }
        });
        this.updateMedlem({
            medlemId: id,
            field: field,
            value: value
        })
    }

    handleDeleteMedlem(id) {
        this.deleteMedlem(id);
    }

    renderMedlemmerTable(medlemmer) {
        return (
            <table className='table table-striped' aria-labelledby="tabelLabel">
                <thead>
                    <tr>
                        <th>Navn</th>
                        <th>Studienummer</th>
                        <th>Semester</th>
                        <th>Telefonnummer</th>
                        <th>Mailaddresse</th>
                        <th>Betalt</th>
                        <th>Modtaget Kort</th>
                        <th>Slet</th>
                    </tr>
                </thead>
                <tbody>
                    {medlemmer.map(medlem =>
                        <tr key={medlem.id}>
                            {this.editableField(medlem.id, medlem.navn, "navn")}
                            {this.editableField(medlem.id, medlem.studienummer, "studienummer")}
                            {this.editableField(medlem.id, medlem.semester, "semester")}
                            {this.editableField(medlem.id, medlem.telefonnummer, "telefonnummer")}
                            {this.editableField(medlem.id, medlem.mailadresse, "mailadresse")}
                            <td>
                                <input type="checkbox"
                                    id={medlem.id + '-betalt-checkbox'}
                                    value={medlem.id}
                                    checked={medlem.betalt}
                                    onChange={(e) => this.handleEditableFieldCheckbox(e, "betalt", medlem.id)}
                                />
                            </td>
                            <td>
                                <input type="checkbox"
                                    id={medlem.id + '-modtagetkort-checkbox'}
                                    value={medlem.id}
                                    checked={medlem.modtagetKort}
                                    onChange={(e) => this.handleEditableFieldCheckbox(e, "modtagetkort", medlem.id)}
                                />
                            </td>
                            <td>
                                <Button variant={this.state.enableDeletion ? "danger" : "outline-danger"}
                                    size="sm"
                                    type="button"
                                    onClick={(e) => { this.handleDeleteMedlem(medlem.id) }}
                                    disabled={!this.state.enableDeletion}
                                >
                                    Tilintetgør
                                </Button>
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
        );
    }

    handleFilterChangeName(event) {
        let newFilter = this.state.filter;
        newFilter.navn = event.target.value;
        this.setState({ filter: newFilter })
        this.loadMembers();
    }

    handleFilterChangeStudienummer(event) {
        let newFilter = this.state.filter;
        newFilter.studienummer = event.target.value;
        this.setState({ filter: newFilter })
        this.loadMembers();
    }

    handleFilterChangeSemester(event) {
        let newFilter = this.state.filter;
        if (event.target.value === "") {
            newFilter.semester = -1;
        } else {
            newFilter.semester = event.target.value;
        }
        this.setState({ filter: newFilter })
        this.loadMembers();
    }

    handleFilterChangeTelefonnummer(event) {
        let newFilter = this.state.filter;
        newFilter.telefonnummer = event.target.value;
        this.setState({ filter: newFilter })
        this.loadMembers();
    }

    handleFilterChangeMailadresse(event) {
        let newFilter = this.state.filter;
        newFilter.mailadresse = event.target.value;
        this.setState({ filter: newFilter })
        this.loadMembers();
    }

    handleFilterChangeBetalt(event) {
        let newFilter = this.state.filter;
        newFilter.betalt = event.target.value;
        this.setState({ filter: newFilter });
        this.loadMembers();
    }

    handleFilterChangeModtagetKort(event) {
        let newFilter = this.state.filter;
        newFilter.modtagetKort = event.target.value;
        this.setState({ filter: newFilter });
        this.loadMembers();
    }

    render() {

        let contents = this.state.loading
            ? <p><em>Loading...</em></p>
            : this.renderMedlemmerTable(this.state.medlemmer);

        return (
            <div>
                <Container>
                    <Row>
                        <h1>Medlemsoversigt</h1>
                    </Row>
                    <Row>
                        <div className="filterArea">
                        <Col>
                        <p>Dette er en oversigt over medlemmer.</p>
                            <Form onSubmit={this.handleFilterSubmit} >
                                <Form.Group controlId="filterNavn">
                                    <Form.Label>Navn</Form.Label>
                                    <Form.Control type="text" placeholder="Dan Jørgensen" onChange={this.handleFilterChangeName} />
                                </Form.Group>
                                <Form.Group controlId="filterStudienummer">
                                    <Form.Label>Studienummer</Form.Label>
                                    <Form.Control type="number" onChange={this.handleFilterChangeStudienummer} />
                                </Form.Group>
                                <Form.Group controlId="filterSemester">
                                    <Form.Label>Semester</Form.Label>
                                    <Form.Control type="number" onChange={this.handleFilterChangeSemester} />
                                </Form.Group>
                                <Form.Group controlId="filterTelefonnummer">
                                    <Form.Label>Telefonnummer</Form.Label>
                                    <Form.Control type="number" onChange={this.handleFilterChangeTelefonnummer} />
                                </Form.Group>
                                <Form.Group controlId="filterEmail">
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control type="text" placeholder="dan.joergensen@yahoo.dk" onChange={this.handleFilterChangeMailadresse} />
                                </Form.Group>
                                <Form.Group>
                                <div key={`inline-betalt`} className="mb-3">
                                    <Form.Check
                                        inline
                                        label="Alle"
                                        name="group2"
                                        type="radio"
                                        id={`betalt-na`}
                                        value={-1}
                                        checked={this.state.filter.betalt === -1 || this.state.filter.betalt === "-1"}
                                        onChange={this.handleFilterChangeBetalt}
                                    />
                                    <Form.Check
                                        inline
                                        label="Betalt"
                                        name="group2"
                                        type="radio"
                                        id={`betalt-true`}
                                        value={1}
                                        checked={this.state.filter.betalt === 1 || this.state.filter.betalt === "1"}
                                        onChange={this.handleFilterChangeBetalt}
                                    />
                                    <Form.Check
                                        inline
                                        name="group2"
                                        label="Ikke Betalt"
                                        type="radio"
                                        id={`betalt-false`}
                                        value={0}
                                        checked={this.state.filter.betalt === 0 || this.state.filter.betalt === "0"}
                                        onChange={this.handleFilterChangeBetalt}
                                    />
                                </div>
                                </Form.Group>
                                <Form.Group>
                                <div key={`inline-modtagetkort`} className="mb-3">
                                    <Form.Check
                                        inline
                                        label="Alle"
                                        name="group1"
                                        type="radio"
                                        id={`modtaget-na`}
                                        value={-1}
                                        checked={this.state.filter.modtagetKort === -1 || this.state.filter.modtagetKort === "-1"}
                                        onChange={this.handleFilterChangeModtagetKort}
                                    />
                                    <Form.Check
                                        inline
                                        label="Modtaget Kort"
                                        name="group1"
                                        type="radio"
                                        id={`modtaget-true`}
                                        value={1}
                                        checked={this.state.filter.modtagetKort === 1 || this.state.filter.modtagetKort === "1"}
                                        onChange={this.handleFilterChangeModtagetKort}
                                    />
                                    <Form.Check
                                        inline
                                        name="group1"
                                        label="Ikke Modtaget"
                                        type="radio"
                                        id={`modtaget-false`}
                                        value={0}
                                        checked={this.state.filter.modtagetKort === 0 || this.state.filter.modtagetKort === "0"}
                                        onChange={this.handleFilterChangeModtagetKort}
                                    />
                                    </div>
                                </Form.Group>
                                <div>
                                        <Button variant="primary"
                                            id={`tillad-sletning-button`}
                                    type="submit">
                                    Genindlæs
                                    </Button>
                                    <Form.Check
                                        inline
                                        name="group1"
                                        label="Tillad Sletning"
                                        type="checkbox"
                                        id={`tillad-sletning-checkbox`}
                                        value={0}
                                        checked={this.state.enableDeletion}
                                        onChange={(e) => {
                                            let oldBool = this.state.enableDeletion;
                                            this.setState({ enableDeletion: !oldBool })
                                        }}
                                    />
                                </div>
                            </Form>
                            </Col>
                        </div>
                    </Row>
                    <Row>
                        {contents}
                    </Row>
                </Container>
            </div>
        );
    }

    async updateMedlem(updateMedlem) {
        const response = await fetch('medlemsoversigt/updatemedlem', {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + this.state.authToken,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(updateMedlem)
        });
        if (response.ok) {
            let newMedlemmer = this.state.medlemmer.slice();
            // Id's come back as strings in data due to JSON conversion.
            switch (updateMedlem.field) {
                case 'navn':
                    newMedlemmer = newMedlemmer.map(medlem => { if (medlem.id == updateMedlem.medlemId) { return { ...medlem, navn: updateMedlem.value } } else { return medlem } });
                    break;
                case 'studienummer':
                    newMedlemmer = newMedlemmer.map(medlem => { if (medlem.id == updateMedlem.medlemId) { return { ...medlem, studienummer: updateMedlem.value } } else { return medlem } });
                    break;
                case 'semester':
                    newMedlemmer = newMedlemmer.map(medlem => { if (medlem.id == updateMedlem.medlemId) { return { ...medlem, semester: parseInt(updateMedlem.value) } } else { return medlem } });
                    break;
                case 'telefonnummer':
                    newMedlemmer = newMedlemmer.map(medlem => { if (medlem.id == updateMedlem.medlemId) { return { ...medlem, telefonnummer: updateMedlem.value } } else { return medlem } });
                    break;
                case 'mailadresse':
                    newMedlemmer = newMedlemmer.map(medlem => { if (medlem.id == updateMedlem.medlemId) { return { ...medlem, mailadresse: updateMedlem.value } } else { return medlem } });
                    break;
                case 'betalt':
                    newMedlemmer = newMedlemmer.map(medlem => { if (medlem.id == updateMedlem.medlemId) { return { ...medlem, betalt: parseInt(updateMedlem.value) } } else { return medlem } });
                    break;
                case 'modtagetkort':
                    newMedlemmer = newMedlemmer.map(medlem => { if (medlem.id == updateMedlem.medlemId) { return { ...medlem, modtagetKort: parseInt(updateMedlem.value) } } else { return medlem } });
                    break;
            }
            this.setState({ medlemmer: newMedlemmer });
        }
    }

    async populateMedlemmer() {
        const filter = this.state.filter;
        const response = await fetch('medlemsoversigt/getmedlemmerwithfilter', {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + this.state.authToken,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(filter)
        });
        const data = await response.json();
        this.setState({ medlemmer: data, loading: false });
    }

    async deleteMedlem(medlemId) {
        const response = await fetch('medlemsoversigt/deletemedlem', {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + this.state.authToken,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ medlemId: medlemId })
        });
        if (response.ok) {
            let newMedlemmer = this.state.medlemmer.slice();
            // Id's come back as strings in data due to JSON conversion.
            newMedlemmer = newMedlemmer.filter(medlem => medlem.id !== medlemId);
            this.setState({ medlemmer: newMedlemmer });
        }
    }
}
